export default {
  web: {
    verify: {
      placeYourFile: 'Place your file here...',
      verifyBtnHeader: 'Verify a certificate',
      verifyBtnText: 'Choose a certificate...',
      documentRegisteredHeader: 'Certificate registered',
      registeredAt: 'Registration Time',
      revokedAt: 'Revocation Time',
      documentNotRegistered: 'Certificate is not registered',
      back: 'Back',
      name: 'Name of the certificate',
      tryAnother: 'Choose another certificate...',
      networkError: 'Network error',
      networkErrorText: 'sproof client is not available, please try again later...',
      validDocument : 'Certificate is valid',
      revokedDocument : 'Certificate was revoked',
      revokedProfile : 'Issuer of certificate is revoked',
      details: 'Details',
      timestamp: 'Timestamp',
      documentHash: 'Document Hash',
      documentId: 'Document ID',
      locationHash: 'Document Link',
      issuerId: 'Issuer ID',
      ipfsHash: 'IPFS Hash',
      blocknumber: 'Blocknumber',
      txHash: 'TransactionHash',
      chain: 'Chain',
      issuedBy: 'Issued by',
      multipleHeader: 'This certificate is registered by several issuers',
      status: 'Status of document',
      registered: 'Certificate is registered',
      notRegistered : 'Certificate is not registered',
      registeredText_1: "Your certificate, with the name \'%{name}\' is registered in the sproof network by %{count} profile.",
      registeredText: 'Your certificate, with the name \'%{name}\' is registered in the sproof network by %{count} profiles.',
      notRegisteredText : 'Your certificate, with the name \'%{name}\' is not registered in the sproof network. It could be that your certificate is in pending mode. Try it again later.',
      revokedBy : "Revoked by",
      documentPendingMode: 'Pending',
      pendingAdditionalText : 'Your certificate is known by the sproof network, but not yet registered on the blockchain. Please try it later again. ',
      scheduleNextCommit: 'You certificate will be registered  ',
      existsByNotConfirmedHeader: 'Certificate is registered, but not confirmed by this verifer.',
      existsByNotConfirmedText: "This certificate is issued by a profile, which is not in the confirmation network of this verifier. Please click the link below for more information about this certificate.",
      clickForMoreInformation: 'Click for more information'
    }
  }
}
