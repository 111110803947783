export default {
  web: {
    verify: {
      placeYourFile: 'Datei hier ablegen...',
      verifyBtnHeader: 'Überprüfen Sie ein Zeugnis',
      verifyBtnText: "Wählen Sie eine Datei aus...",
      documentRegisteredHeader: 'Zeugnis ist registriert',
      registeredAt: 'Zeitstempel',
      documentNotRegistered: 'Zeugnis nicht registriert',
      back: 'Zurück',
      tryAnother: 'Überprüfen Sie ein weiteres Zeugnis...',
      name: 'Name des Zeugnis',
      revokedAt: 'Widerrufszeitpunk',
      networkError: 'Netzwerkfehler',
      networkErrorText: 'sproof client ist momentan nicht verfügbar, probiere es später erneut...',
      validDocument : 'Zeugnis ist gültig',
      revokedDocument : 'Zeugnis ist ungültig',
      revokedProfile : 'Aussteller ist ungültig',
      timestamp: 'Zeitstempel',
      documentHash: 'Zeugnis Hash',
      documentId: 'Zeugnis ID',
      locationHash: 'Zeugnis Link',
      issuerId: 'Aussteller ID',
      ipfsHash: 'IPFS Hash',
      blocknumber: 'Blocknummer',
      txHash: 'Transaction Hash',
      chain: 'Chain',
      multipleHeader: 'Dieses Zeugnis wurde von meheren Ausstellern eingetragen',
      status: 'Status des Zeugnis',
      details: 'Details',
      registered: 'Zeugnis ist registriert',
      notRegistered : 'Zeugnis ist nicht registriert',
      registeredText_1: "Ihr Zeugnis mit dem Namen \'%{name}\' ist von einem Profil im sproof Netzwerk registriert worden.",
      registeredText: "Ihr Zeugnis mit dem Namen \'%{name}\' ist von %{count} Profilen im sproof Netzwerk registriert worden.",
      notRegisteredText : "Ihr Zeugnis mit dem Namen \'%{name}\' ist nicht im sproof Netzwerk registriert.",
      issuedBy : "Ausgestellt von",
      revokedBy : "Widerufen von",
      documentPendingMode: 'In Bearbeitung',
      pendingAdditionalText : 'Ihr Zeugnis ist dem sproof Netzwerk bereits bekannt, aber noch nicht digital unterschrieben. Bitte prüfen Sie ihr Zeugnis später nocheinmal.',
      scheduleNextCommit: 'Ihr Zeugnis wird digital unterschieben: ',
      existsByNotConfirmedHeader: 'Ihr Zeugnis ist registriert, aber nicht bestätigt.',
      existsByNotConfirmedText: "Dieses Zeugnis wurde im sproof Netzwerk registriert. Der Inhaber dieser Website hat aber die Identität des Aussteller noch nicht bestätigt. Klicken Sie auf den Link um mehr informationen zu diesem Zeugnis zu erhalten.",
      clickForMoreInformation: 'Mehr Informationen zu diesem Zeugnis.'
    },
  }
}
